<template>
<div class="home home--static">
	<section class="section">
		<div class="container">
			<div class="article">
        <div class="row">
					<div class="col-12 col-xl-8">
            <ul class="nav nav-tabs comments__title comments__title--tabs" id="comments__tabs" role="tablist" style="border-bottom: solid rgb(180, 192, 200)">
								<li class="nav-item">
									<a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true" style="background: linear-gradient(0deg,#82c9f4 0,#E0E9F2 ) !important; border-radius: 12px 12px 0 0;border-color: rgba(185, 196, 202, 0.39);">
										<h4>إسال مستشارك ؟</h4>
									</a>
								</li>
							</ul>
              <form class="comments__form" @submit.prevent="submitForm()">
								<div class="sign__group">
									<textarea  id="text" name="text" v-model="content_name" class="sign__textarea" placeholder="Add comment"></textarea>
								</div>
								<button class="sign__btn">
                  <span v-if="loading" class="px-1 arabickufi">جااري الارسال</span> &nbsp;
                    <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                    <span v-else class="arabickufi">ارسال الاستشارة</span>
                </button>
						  </form>
						  <div class="comments">
							  <ul class="nav nav-tabs comments__title comments__title--tabs" id="comments__tabs" role="tablist" style="border-bottom:1px solid rgb(180, 192, 200)">
								  <li class="nav-item">
									  <a class="nav-link active" data-toggle="tab" href="#tab-1" role="tab" aria-controls="tab-1" aria-selected="true" style="background: linear-gradient(0deg,#82c9f4 0,#E0E9F2 ) !important;border-radius: 12px 12px 0 0; border-color: rgba(185, 196, 202, 0.39);">
										  <h4>استشاراتي</h4>
									  </a>
								  </li>
							  </ul>
							  <div class="tab-content">
								  <div class="tab-pane fade show active" id="tab-1" role="tabpanel">
									  <ul class="comments__list">
                      <template v-for="publish in consulting" :key="publish.id">
										    <li class="comments__item" >
											    <div class="comments__autor">
                            <img class="comments__avatar" src="../../assets/avatar.svg" alt=""/>
												    <span class="comments__name">{{ publish.mdn }}</span>
												    <span class="comments__time">{{ publish.create_date.substring(0,10) }}</span>
											    </div>
											    <p class="comments__text">{{ publish.consultation }}</p>
										    </li>
                        <template v-for="res in response" :key="res.id">
										      <li class="comments__item comments__item--answer" v-if="publish.id == res.consulting_id">
											      <div class="comments__autor">
                              <img class="comments__avatar" src="../../assets/fav.png" alt=""/>
												      <span class="comments__name">{{ $route.params.name_cat }}</span>
												      <span class="comments__time">{{ res.create_date.substring(0,10) }}</span>
											      </div>
											      <p class="comments__text">{{ res.response }}</p>
										      </li>
                        </template>
                      </template>
									  </ul>
								  </div>
							  </div>
						  </div>
					  </div>
					  <div class="col-12 col-xl-4">
						  <div class="sidebar sidebar--mt">
							  <div class="row row--grid">
								  <div class="col-12">
									  <h5 class="sidebar__title" style="color: rgb(2, 96, 151);text-align: start;">مقترحات</h5>
								  </div>
								  <div class="col-6 col-sm-4 col-lg-3 col-xl-6" v-for="publish in All.slice(0,12)" :key="publish.id">
                    <!-- <div class="profile-card">
                      <div class="card">
                        <a @click="getFeed(publish)" class="card__cover" style="height: 230px;">
                          <img v-lazy="publish.img" alt="">
                          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <div class="shotal">
                              <h3 class="card__title" >
                                <a @click="getFeed(publish)" style="color: #fff !important;">{{ publish.title_ar }}</a>
                              </h3>
                              <ul style="justify-content: center;" class="card__list">
                                <li style="margin-left: 0;">{{ publish.name_ar}}</li>
                                <li></li>
                              </ul>
                            </div>
                          </a>
                        </div>
                      </div> -->
                      <div id="busicard" @click="getFeed(publish)">
                                          <div class="menu">
                                            <div class="movembar">
                                              <h4 class="texto"><svg class="svg-icon"
                                              style="width: 20px;height:20px;margin-left:5px" viewBox="0 0 20 20">
                                              <path fill="#fff"
                                                d="M11.088,2.542c0.063-0.146,0.103-0.306,0.103-0.476c0-0.657-0.534-1.19-1.19-1.19c-0.657,0-1.19,0.533-1.19,1.19c0,0.17,0.038,0.33,0.102,0.476c-4.085,0.535-7.243,4.021-7.243,8.252c0,4.601,3.73,8.332,8.332,8.332c4.601,0,8.331-3.73,8.331-8.332C18.331,6.562,15.173,3.076,11.088,2.542z M10,1.669c0.219,0,0.396,0.177,0.396,0.396S10.219,2.462,10,2.462c-0.22,0-0.397-0.177-0.397-0.396S9.78,1.669,10,1.669z M10,18.332c-4.163,0-7.538-3.375-7.538-7.539c0-4.163,3.375-7.538,7.538-7.538c4.162,0,7.538,3.375,7.538,7.538C17.538,14.957,14.162,18.332,10,18.332z M10.386,9.26c0.002-0.018,0.011-0.034,0.011-0.053V5.24c0-0.219-0.177-0.396-0.396-0.396c-0.22,0-0.397,0.177-0.397,0.396v3.967c0,0.019,0.008,0.035,0.011,0.053c-0.689,0.173-1.201,0.792-1.201,1.534c0,0.324,0.098,0.625,0.264,0.875c-0.079,0.014-0.155,0.043-0.216,0.104l-2.244,2.244c-0.155,0.154-0.155,0.406,0,0.561s0.406,0.154,0.561,0l2.244-2.242c0.061-0.062,0.091-0.139,0.104-0.217c0.251,0.166,0.551,0.264,0.875,0.264c0.876,0,1.587-0.711,1.587-1.587C11.587,10.052,11.075,9.433,10.386,9.26z M10,11.586c-0.438,0-0.793-0.354-0.793-0.792c0-0.438,0.355-0.792,0.793-0.792c0.438,0,0.793,0.355,0.793,0.792C10.793,11.232,10.438,11.586,10,11.586z">
                                              </path>
                                            </svg>{{ publish.file_duration }} </h4>
                                              <!-- <a href="#" class="mbut"><span class="card__rating">
                                                                                      {{ publish.file_duration }}
                                                                                    </span></a> -->
                                            </div>
                                            <!-- <div class="movegear">
                                                <a href="#"><div class="gear"></div></a>
                                            </div> -->
                                          </div>
                                          <div class="user pointer">
                                            <img class="img2" :src="publish.img">
                                          </div>
                                          
                                        
                                          <div class="bfollow pointer">
                                            <a @click="getFeed(publish)">مشاهدة</a>
                                          </div>
                                          <div class="content pointer">
                                            <p class="one-line-text">{{ publish.title_ar }}</p>
                                            <h6 style="margin-top: -1vh;font-size: 85%;"> {{ publish.sub_cat_name }} </h6>
                                          </div>
                                          
                                        </div>
                    </div>
                    
							    </div>
						    </div>
					    </div>
				    </div>
          </div>
        </div>
  </section>
</div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
import { useToast } from 'vue-toastification';
export default {
    name: 'AppConsulting',
    props: {
        sub_cat: String,
        name_cat: String
    },
    data () {
        return {          
          // content_name:"",
          // loading: false,
        }    
    },
	// computed: {
  //       isDisabled() {
  //          return this.content_name.length > 0;
  //       }
  //   },
    async setup(props) {
    const toast = useToast();
    const router = useRouter();
    const cookie = useCookie();
    const consulting = ref([]);
    const response = ref([]);
    const All = ref([]);
    const loading = ref(false);
    const content_name = ref("");
    try {
      await HTTP.get(`getConsultingByMDN.php?cat_id=${props.sub_cat}&mdn=`+cookie.getCookie("msisdn")).then((res) => {
        consulting.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
    try {
      await HTTP.get(`getConsultingResByID.php?ID=`+cookie.getCookie("msisdn")).then((res) => {
        response.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
  if(props.sub_cat == 10) {
    try {
      await HTTP.get(`GetAllContent.php?Content&catID=2&LIMIT=12&OFFSET=0`).then((res) => {
        All.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    try {
      await HTTP.get(`GetAllContent.php?Content&catID=1&LIMIT=12&OFFSET=0`).then((res) => {
        All.value = res.data.Content; 
      });
    } catch (err) {
      console.log(err);
    }
  }
	
    const submitForm = () => {
      loading.value = true;
      if(cookie.isCookieAvailable("msisdn")){
        HTTP.post(`AddConsultingResponseByID.php?cat_id=${props.sub_cat}&mdn=`+cookie.getCookie("msisdn")+`&consultation=`+content_name.value, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then((response) => {
                if(!response.data){
                    alert('File not uploaded.');
                }else{
                    console.log('seccess')
                    content_name.value = "";                 
                    try {
                     HTTP.get(`getConsultingByMDN.php?cat_id=${props.sub_cat}&mdn=` + cookie.getCookie("msisdn")).then((res) => {
                      consulting.value = res.data.Content;
                    });
                  } catch (err) {
                    console.log(err);
                  }                   
                }
            }).catch(function (error) {
                console.log(error);
            }).finally(() => {
                // this.loading = false;
				setTimeout(() => loading.value = false, 2500);
            });
      } else {
        toast.warning("عذرا ، سجل دخولك اولاً ", {
          timeout: 2000,
        });
      }
           
    };
    const getFeed = (publish) => {
        cookie.setCookie('cat_id', publish.cat_id);
        router.push({ name: "Content", params: { Content_id: publish.id } });
    };
      return { response, getFeed,submitForm, consulting, All ,content_name,loading};
  },
  methods: {
    
  }
}
</script>

<style scoped>

.pointer {
  cursor: pointer;
}

.comments__item {
    background-image: linear-gradient(0deg,#c8cdd0 0,#E9F5FB );
}
.comments__name {
  margin-right: 0;
  display: flex;
font-size: 20px;
color: rgb(22, 72, 102);
line-height: 20px;
font-weight: 500;
text-align: match-parent;
margin-right: 70px;
font-family: 'Noto Kufi Arabic', sans-serif;
}
.comments__time {
  margin-right: 70px;
}


.cardBox {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.card {
  position: absolute;
width: 95%;
height: 95%;
background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
border-radius: 20px;
z-index: 5;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
text-align: center;
color: #ffffff;
overflow: hidden;
padding: 0;
cursor: pointer;
box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
margin-top: 0;

}

.card h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card:hover .content {
  transform: translateY(0);
  opacity: 1;
}

.cardBox::before {
  content: "";
position: absolute;
width: 40%;
height: 150%;
background: #40E0D0;
background-image: linear-gradient(0deg,#026097 0,#71C6F7 );
-webkit-animation: glowing01 200s linear infinite;
animation: glowing01 200s linear infinite;
transform-origin: center;
-webkit-animation: glowing 200s linear infinite;
animation: glowing 50s linear infinite;
}


.cardBox2 {
  width: auto;
height: 26vh;
position: relative;
display: grid;
place-items: center;
overflow: hidden;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px, rgba(0, 0, 0, 0.5) 0px 2px 15px 0px;
margin-bottom: 1vh;
margin-top: 1vh;
}
.cardBox2::before {
  content: "";
  position: absolute;
  width: 140%;
  height: 70%;
  background: #40E0D0;
background: -webkit-linear-gradient(to right, #004d4d, #00b3b3, #1affff);
background: linear-gradient(to right, #004d4d, #00b3b3, #1affff);
  animation: glowing01 200s linear infinite;
  transform-origin: center;
  animation: glowing 200s linear infinite;
}
.card2 {
  position: absolute;
  width: 95%;
  height: 92%;
  background: #fff;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 0;
  cursor: pointer;
  /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset; */
    margin-top: 0;
}

.card2 h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  font-weight: 800;
  pointer-events: none;
  opacity: 0.1;
}
/*
 span {
  top: 75%;
} 
*/
.card2 .content h3 {
  font-size: 3rem;
  padding-bottom: 10px;
}
.card2 .content p {
  font-size: 1.6rem;
  line-height: 25px;
}
.card2 .content {
  transform: translateY(100%);
  opacity: 0;
  transition: 0.3s ease-in-out;
}

.card2:hover .content {
  transform: translateY(0);
  opacity: 1;
}




@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>
<style scoped>

  .img2 {
    height: auto;
    width: 100%;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  #busicard {
    position: relative;
    background: linear-gradient(to left, #00D2C8, #179C8C);
    border-radius: 35px;
    height: 232px;
    max-width: 440px;
    margin: 10px auto;
  }
  #busicard h1 {
    color: #FAEAFF;
  }
  .movembar {
    display: flex;
      flex-direction: row;
      justify-content: baseline;
      align-items: flex-start;
  }
  .movegear {
    display: flex;
      flex-direction: row-reverse;
      justify-content: flex-start;
      align-items: flex-start;
    margin-top: -2px;
  }
  .mbar {
    width: 10px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    margin: 17px 35px 0 36px;
  }
  .mbar:before {
    content: '';
    width: 12px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 0px;
    left: 0;
  }
  .mbar:after {
    content: '';
    width: 18px;
    height: 3px;
    background: #FAEAFF;
    position: absolute;
    top: 5px;
    left: 0;
  }
  .mbut:hover .mbar, .mbut:hover .mbar:before, .mbut:hover .mbar:after {
    background: #2F4858;
  }
  .user {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
          margin-top: -24px;
  }
  .user img {
    border-radius: 35px 35px 0 0;
  width: 100%;
  /* border: 4px solid #FAEAFF; */
  /* margin-top: 1vh; */
  height: 130px;
  }
  .user img:hover{
    filter: saturate(0);
  -webkit-filter: saturate(0);
  -moz-filter: saturate(0);
  }
  .gear {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #eee 25%, #FAEAFF 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #eee 40%, #FAEAFF 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #eee 43%, #FAEAFF 57%, transparent 57%);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin: 17px 35px 0 36px;
  }
  .gear:hover {
    background-image:
      radial-gradient(at center center, #00D2C8 23%, #242424 25%, #2F4858 57%, transparent 53%),
      linear-gradient(0deg, transparent 43%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(45deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%),
      linear-gradient(90deg, transparent 40%, #242424 40%, #2F4858 60%, transparent 60%),
      linear-gradient(135deg, transparent 43%, #242424 43%, #2F4858 57%, transparent 57%);
  }
  .info li {
    color: #eee;
    font-size: 0.8em;
    display: inline-block;
    margin: 0 6px;
  }
  .hrtop {
    display: inline-block;
    background-color: #FAEAFF;
    width: 2px;
    height: 22px;
    margin: -2px auto;
  }
  .contacts {
    min-height: 100px;
  }
  .contacts i {
    display: inline-block;
    margin: 20px 0;
  }
  .contacts a {
    margin: 0 6px;
  }
  .bfollow {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    /*! margin: 25px 0 0 0; */
  }
  .bfollow a {
    position: absolute;
    box-shadow: 0 0 15px 1px #000;
    color: #FAEAFF;
    background-color: #1F937D;
    border-radius: 30px;
    padding: 7px 49px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .bfollow a:hover {
    font-weight: bold;
    color: #2F4858;
    background-color: #FAEAFF;
    padding: 8px 56px;
    transition: 350ms;
      -webkit-transition: all .3s;
  }
  .content {
    color: #FAEAFF;
    background: linear-gradient(to bottom, #2F4858, #2C687C);
    border-radius: 0 0 30px 30px;
    min-height: 85px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content p {
    min-width: 100%;
  border-top: none;
  text-align: center;
  font-family: "Noto Kufi Arabic";
  font-size: 16px;
  margin-top: 20px;
  }
  .lock {
    width: 26px;
    height: 16px;
    background: #FAEAFF;
    position: relative;
    border-radius: 4px;
    top: 30px;
  }
  .lock:before {
    content: '';
    width: 6px;
    height: 6px;
    background: transparent;
    border: 6px solid #FAEAFF;
    border-bottom: 0;
    border-radius: 6px 6px 0 0;
    position: absolute;
    bottom: 16px;
    right: 4px;
  }
  a .fa {
    font-size: 36px;
    transition: 500ms;
  }
  a:hover .fa {
    color: #2F4858;
    transition: 500ms;
  }
  a .fa-twitter-square, a .fa-instagram, a .fa-facebook-square {
    color: #FAEAFF;
  }
  
  .texto{
    margin-right: 15px;
  text-align: right;
  font-size: 15px;
  background: #1F937D;
  border-radius: 0 10px 10px 15px;
  padding: 0 16px;
  z-index: 9;
  font-weight: 900;
  }
  
  </style>